<!-- 设备管理 -->
<template>
  <div id="device">
    <div class="tree">
      <div class="tree-title">组织结构</div>
      <el-input placeholder="输入检索内容" suffix-icon="el-icon-search" v-model.trim="filterText"></el-input>
      <!-- <el-checkbox v-model="allSelect">全选</el-checkbox> -->
      <el-tree ref="tree" v-loading="loading" :default-expand-all="false" :show-checkbox="false" :data="treeData"
               :check-on-click-node="false" :props="defaultProps" node-key="id" :filter-node-method="filterNode"
               @node-click="handleCheck"></el-tree>
    </div>
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>设备在线状态</span>
      </div>
      <div class="query-form">
        <el-form class="form" ref="form" :inline="true" :model="requestParam">
          <el-form-item label="场站名称">
            <el-select filterable @clear="clearSelect" v-model="requestParam.stationName"  :popper-append-to-body="false" clearable placeholder="请选择场站名称"  >
              <el-option v-for="item in stationData" :key="item.id" :label="item.stationName +' '+ item.stationNumber"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备名称">
            <el-input v-model.trim="requestParam.equipmentName" placeholder="请输入设备名称" clearable @input="handleInput">
            </el-input>
          </el-form-item>
          <el-form-item label="设备编号">
            <el-input v-model.trim="requestParam.equipmentCode" placeholder="请输入设备编号" clearable @input="handleInput1">
            </el-input>
          </el-form-item>
          <el-form-item label="设备类型">
            <el-select v-model="requestParam.equipmentType" @clear="clearSelect1" :popper-append-to-body="false" clearable placeholder="请选择设备类型">
              <el-option v-for="item in sblxData" :key="item.dictValue" :label="item.dictName"
                         :value="item.dictValue"></el-option>
            </el-select>
            <!-- </el-input> -->
          </el-form-item>
          <el-form-item label="柜子名称">
            <el-input v-model.trim="requestParam.cabinetName" placeholder="请输入柜子名称" clearable @input="handleInput2">
            </el-input>
          </el-form-item>
          <el-form-item label="安装位置">
            <el-select v-model="requestParam.location" @clear="clearSelect2" :popper-append-to-body="false" clearable placeholder="请选择安装位置">
              <el-option v-for="item in positionData" :key="item.dictValue" :label="item.dictName"
                         :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备状态">
            <el-select v-model="requestParam.equipmentStatus" filterable :popper-append-to-body="false" clearable placeholder="请选择" @clear="clearSelect3">
              <el-option
                  v-for="item in optionsZ"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="query-button">
          <el-button type="primary" icon="el-icon-search" @click="formQuery">查询</el-button>
        </div>
      </div>

      <el-table class="table" :data="tableData">
        <el-table-column align="center" type="index" label="序号" width="75">
          <template slot-scope="scope">
            <span>{{
                (requestParam.pageNum - 1) * requestParam.pageSize +
                scope.$index +
                1
              }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="customerName" label="客户名称">
        </el-table-column>
        <el-table-column align="center" prop="stationName" label="场站名称">
        </el-table-column>
        <el-table-column align="center" prop="stationCode" label="场站编码" >
        </el-table-column>
        <el-table-column align="center" prop="equipmentName" label="设备名称" >
        </el-table-column>
        <el-table-column align="center" prop="equipmentCode" label="设备编号">
        </el-table-column>
        <el-table-column align="center" prop="equipmentType" label="设备类型">
        </el-table-column>
        <el-table-column align="center" prop="cabinetName" label="柜子名称">
        </el-table-column>
        <el-table-column align="center" prop="cabinetCode" label="柜子编号">
        </el-table-column>
        <el-table-column align="center" prop="location" label="安装位置">
        </el-table-column>
        <el-table-column align="center" prop="circuitName" label="回路名称">
        </el-table-column>
        <el-table-column align="center" prop="circuitCode" label="回路编号">
        </el-table-column>
        <el-table-column align="center" prop="equipmentStatus" label="设备状态">
          <template slot-scope="scope">
            <span v-if="scope.row.equipmentStatus === '在线'" style="color: #09eba9;">{{'在线'}}</span>
            <span v-if="scope.row.equipmentStatus === '离线'" style="color: #f95b66;">{{'离线'}}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <button class="home-page" @click="homePage">首页</button>
        <el-pagination :current-page="requestParam.pageNum" :page-sizes="[10, 20, 30, 40]"
                       layout="prev, pager, next, sizes,slot" prev-text="上一页" next-text="下一页" :total="total" background
                       @size-change="changeSize" @current-change="changeCurrent">
        </el-pagination>
        <button class="tail-page" @click="tailPage">尾页</button>
        <div class="jump-pagination">
          <span class="text">跳至</span>
          <el-input v-model.number="inputValue" size="mini" class="pagination-input" @input="checkPageNum"
                    @change="inputValueChange"></el-input>
          <span class="text">页</span>
          <button class="confirm" @click="confirm">确定</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { deviceApi } from "@/api/device.js";
import { energyStationApi } from "@/api/energyStation.js";
export default {
  name: "",
  components: { },
  data() {
    return {
      optionsZ: [
        {value: '0',label: '离线'},
        {value: '1',label: '在线'},
      ],
      loading: false,
      tableData: [],
      requestParam: {
        pageNum: 1,
        pageSize: 10,
        equipmentCode: null,
        equipmentName: null,
        cabinetName: null,
        equipmentType: null,
        location: null,
        stationName: null,
        customerName: null,
        equipmentStatus: null
      },
      total: 0,
      inputValue: "",
      id: "",
      requestType: 0,
      checkedNodes: [],
      defaultProps: {
        children: "companyInfoList",
        id: "id",
        label: "companyName",
      },
      treeData: [],
      filterText: "",
      viewVisible: false,
      deleteVisible: false,
      dialogVisible: false,
      detailsData: {},
      stationData: [],
      circuitData: [],
      positionData: [],
      sblxData:[],
      pickerOptionsStart: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 86400000;
        },
      },
      //选择场站
      stationName: "",
      dialogStatus: false,
      stationId: 0,
      allButtonPower: [],
    };
  },
  created() {
    this.$store.commit("increment", "运维管理");
    this.$store.commit("selectChild", "设备在线状态");
    this.$store.commit("selectChildren", "");
  },
  mounted() {
    this.queryUnitName();
    this.queryPosition();
    this.querySblx()
    // this.allButtonPower = sessionStorage.getItem('roleInfo');
  },

  methods: {
    handleInput(value) {
      if(!value) {
        this.requestParam.equipmentName = null
      }
    },
    handleInput1(value) {
      if(!value) {
        this.requestParam.equipmentCode = null
      }
    },
    handleInput2(value) {
      if(!value) {
        this.requestParam.cabinetName = null
      }
    },
    clearSelect(){
      console.log(this.requestParam.stationName);
      this.requestParam.stationName = null
    },
    clearSelect1(){
      this.requestParam.equipmentType = null
    },
    clearSelect2(){
      this.requestParam.location = null
    },
    clearSelect3(){
      this.requestParam.equipmentStatus = null
    },
    //设备类型下拉
    querySblx(){
      energyStationApi
          .queryDictionary({ dictCode: "deviceModelType" })
          .then((res) => {
            if (res.code === 200) {
              this.sblxData = res.data;
            }
          });
    },
    selectStation() {
      this.dialogStatus = true;
    },
    //-----------------------
    filterNode(value, data) {
      if (!value) return true;
      return data.companyName.indexOf(value) !== -1;
    },
    formQuery() {
      this.requestParam.pageNum = 1;
      this.queryEquipmentInfo();
    },
    checkPageNum(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.inputValue = value;
    },
    //页数输入
    inputValueChange() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      if (this.inputValue < 1) {
        this.inputValue = 1;
      } else {
        this.inputValue =
            this.inputValue < lastPage ? this.inputValue : lastPage;
      }
    },
    //首页
    homePage() {
      this.requestParam.pageNum = 1;
      this.queryEquipmentInfo();
    },
    //尾页
    tailPage() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      this.requestParam.pageNum = lastPage;
      this.queryEquipmentInfo();
    },
    confirm() {
      this.requestParam.pageNum = this.inputValue;
      this.queryEquipmentInfo();
      this.inputValue = "";
    },
    changeSize(pageSize) {
      this.requestParam.pageSize = pageSize;
      this.queryEquipmentInfo();
    },
    changeCurrent(pageNum) {
      this.requestParam.pageNum = pageNum;
      this.queryEquipmentInfo();
    },
    handleCheck(checkedNodes, event) {
      if (this.requestParam.stationId) {
        this.requestParam.stationId = "";
      }
      if (this.requestParam.customerName == checkedNodes.id) {
        this.checkedNodes=''
        this.requestParam.customerName=null
        event.isCurrent = false
        this.queryStation(this.requestParam.customerName)
        this.queryEquipmentInfo();
      }else{
        event.isCurrent = true
        this.checkedNodes = checkedNodes;
        this.requestParam.customerName = checkedNodes.id;
        this.queryStation(this.requestParam.customerName)
        this.queryEquipmentInfo();
      }

    },
    queryEquipmentInfo() {
      deviceApi.queryEquipmentInfo(this.requestParam).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data;
          this.total = res.total;
        }
      });
    },
    queryDevice() {
      deviceApi.queryDevice(this.requestParam).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data;
          this.total = res.total;
        }
      });
    },
    queryStation(companyId) {
      energyStationApi.queryStation({companyId:companyId}).then((res) => {
        if (res.code === 200) {
          this.stationData = res.data;
        }
      });
    },
    // 获取安装位置
    queryPosition() {
      energyStationApi
          .queryDictionary({ dictCode: "position_type" })
          .then((res) => {
            if (res.code === 200) {
              this.positionData = res.data;
            }
          });
    },
    queryUnitName() {
      this.loading = true;
      deviceApi.queryUnitName().then((res) => {
        this.loading = false;
        if (res.code === 200) {
          this.queryEquipmentInfo();
          this.treeData = res.data;
          this.queryStation();
        }
      });
    },
    queryCircuitName() {
      let obj = {
        stationId: this.deviceData.stationId
      }
      deviceApi.queryCircuitName(obj).then((res) => {
        if (res.code === 200) {
          this.circuitData = res.data;
          if (this.circuitData.length == 0) {
            if (this.deviceData.loopId) {
              this.deviceData.loopId = ''
            }
          }else{
            let idArr=this.circuitData.map(item=>item.id)
            if(!idArr.includes(this.deviceData.loopId)){
              this.deviceData.loopId = ''
            }
          }
        }
      });
    },
  },
  watch: {
    // 如果只需要监听对象中的一个属性值，则可以做以下优化：使用字符串的形式监听对象属性：
    "requestParam.pageNum": {
      // 执行方法
      handler(newValue, oldValue) {
        this.inputValue = newValue;
      },
      deep: true, // 深度监听
      immediate: true, // 第一次改变就执行
    },
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    "deviceData.stationId": {
      // 执行方法
      handler(newValue, oldValue) {
        if (newValue) {
          this.stationId = newValue;
        }
      },
      deep: true, // 深度监听
      immediate: true, // 第一次改变就执行
    },
  },
  computed: {
    pickerOptionsEnd() {
      const _this = this;
      return {
        disabledDate(time) {
          const limitDate = new Date(_this.deviceData.manufacturerTime);
          limitDate.setHours(0, 0, 0, 0); // 重置时间为当天的开始时间

          return time.getTime() < limitDate.getTime();
        },
      };
    },
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
#device {
  width: 100%;
  min-height: 781px;
  font-size: 16px;
  display: flex;
}

.flex-horizontal-between {
  display: flex;
  justify-content: space-between;
}

.main {
  width: calc(100% - 240px);
  background: url("../../assets/images/content-background.png") no-repeat center;
  background-size: cover;
  padding: 22px 0 26px 30px;
  box-sizing: border-box;
}

/* 弹窗 */
.dialog-content {
  margin: 34px 46px 0 60px;
}

/* input */
.dialog-form :deep() .el-date-editor.el-input {
  width: 260px;
}

.dialog-form :deep() .el-input {
  width: 260px;
}

.dialog-form :deep() .el-input .el-input__inner {
  height: 35px;
  width: 100%;
  background-color: #061b45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

.dialog-form :deep() .el-textarea textarea {
  width: 655px;
  background-color: #061b45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

.dialog-form :deep() .el-textarea textarea::placeholder {
  font-size: 12px;
  color: #6180a8;
}

.dialog-form :deep() .el-input input::placeholder {
  font-size: 12px;
  color: #6180a8;
}

.dialog-form :deep() .special {
  margin-left: 25px;
}

.dialog-form :deep() .block {
  width: 655px;
}

.dialog-form :deep() .block .el-input {
  width: 655px;
}

/* 时间选择器 */
/*时间日期选择器默认样式修改 */
.dialog-form :deep() .el-picker-panel .el-input {
  width: 100%;
}

::v-deep .el-time-panel {
  background: #041c4c;
  border: 0;
  color: #aed6ff;
}

::v-deep .el-time-panel__footer {
  border-color: #aed6ff;
}

::v-deep .el-time-spinner__item {
  color: #aed6ff;
}

::v-deep .el-time-spinner__item.active:not(.disabled) {
  color: #aed6ff;
}

::v-deep .el-time-panel__btn {
  color: #aed6ff;
}

::v-deep .el-time-panel__btn.confirm {
  color: #409eff;
}

::v-deep .el-picker-panel__footer {
  color: #aed6ff;
  background: #041c4c;
}

::v-deep .is-plain {
  background: #176ad2;
  border: 0;
  color: #fff;
}

::v-deep .el-time-spinner__item:hover:not(.disabled):not(.active) {
  background: #041c4c;
}

::v-deep .is-plain:hover {
  background: #176ad2;
  border: 0;
  color: #fff;
}

/* 选择场站样式 */
.dialog-form :deep() .el-input-group__append {
  background: #143168;
  color: #fff;
  border: 0;
  cursor: pointer;
}
.dialog-form:deep() .el-form-item__label{
  width: 98px;
}
</style>
